import React, { useContext } from "react"
import Loading from "mill/components/Loader"
import { Formik, Form, Field } from "formik"

import { useQuery, useMutation } from "@apollo/client"
import FETCH_PROFILE from "mill/graphql/FetchProfile"
import UPDATE_PROFILE from "mill/graphql/UpdateProfile"

import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"

import Button from "shared/components/Button/index.legacy.js"
import { FormikTextField as TextField } from "mill/components/TextField"
import { FormikCheckbox as Checkbox } from "shared/components/Checkbox"
import { ProfileSchema, SSOProfileSchema } from "mill/utils/schemas"
import PasswordErrorWell from "mill/components/PasswordErrorWell"
import useNotifications from "shared/hooks/useNotifications"
import styled from "styled-components"
import { disabledBackground } from "mill/utils/colors"

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 2rem 0;

  input {
    width: 100%;
  }
`

const Fieldset = styled.fieldset`
  margin-bottom: 2rem;
  border: 4px solid ${disabledBackground};
  padding: 2rem;

  legend {
    background: ${disabledBackground};
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.4rem;
  }
`

const UpdateProfile = () => {
  const { displayNotification } = useNotifications()
  const { featureFlags } = useContext(FeatureFlagsContext)
  const { data, loading, error, networkStatus } = useQuery(FETCH_PROFILE)
  const [updateProfile] = useMutation(UPDATE_PROFILE)

  if (loading && networkStatus !== 2) return <Loading />
  const viewer = data.viewer

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data.viewer,
          currentPassword: "",
          newPassword: "",
          newPasswordConfirmation: ""
        }}
        validationSchema={viewer.usingSso ? SSOProfileSchema : ProfileSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true)
          updateProfile({
            variables: {
              attributes: {
                name: values.name,
                nickname: values.nickname,
                email: values.email,
                dailyNotifications: values.dailyNotifications,
                mobileNumber: values.mobileNumber,
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
              }
            },
            update: (store, { data: { updateProfile } }) => {
              store.writeQuery({
                query: FETCH_PROFILE,
                data: {
                  viewer: updateProfile.viewer
                }
              })
            }
          })
            .then(({ data: { updateProfile } }) => {
              if (updateProfile.errors) {
                displayNotification({
                  text: updateProfile.errors[0]
                })
                if (
                  updateProfile.errors[0] == "Current password was incorrect"
                ) {
                  actions.setErrors({
                    currentPassword: "Incorrect password provided"
                  })
                }
              } else {
                displayNotification({
                  text: `Your profile was updated.`,
                  style: "success"
                })
                if (
                  updateProfile.redirection &&
                  updateProfile.redirection.logout
                ) {
                  window.location = `/logout?hint=${updateProfile.viewer.email}`
                }
              }
            })
            .catch(e => {
              if (e.graphQLErrors) {
                const error = e.graphQLErrors[0].message
                actions.setStatus(error.message)
                actions.setErrors({
                  newPassword: "Set a stronger password",
                  newPasswordConfirmation: "Set a stronger password"
                })
              }
            })
          actions.setSubmitting(false)
        }}>
        {({ status, dirty, errors, isSubmitting }) => {
          const hasErrors = !(Object.keys(errors).length === 0)
          return (
            <Form>
              <Fieldset>
                <legend>Details</legend>

                {!featureFlags.emailless && (
                  <Field
                    withFieldset
                    name={"email"}
                    autoCapitalize={"off"}
                    autoComplete={"email"}
                    type="text"
                    component={TextField}
                    label="Your email address"
                    placeholder="super.seneca@yarno.com.au"
                    disabled={viewer.emailModifiable}
                  />
                )}

                <Field
                  withFieldset
                  name={"name"}
                  autoComplete={"name"}
                  type="text"
                  component={TextField}
                  label="Your name"
                  helpText="Your first and last name."
                  placeholder="Larry Longbottom"
                />

                <Field
                  withFieldset
                  name={"nickname"}
                  autoComplete={"nickname"}
                  type="text"
                  component={TextField}
                  label="What would you like us to call you?"
                  helpText="This will be used in-app and in email communications."
                  placeholder="Larry the learner"
                />

                {featureFlags.mobileNumbersEnabled && (
                  <Field
                    withFieldset
                    name={"mobileNumber"}
                    type="text"
                    component={TextField}
                    label="Your mobile number"
                    placeholder="04xx xxx xxx"
                  />
                )}

                {!featureFlags.emailless && (
                  <Field
                    withFieldset
                    inline
                    marginTop={2}
                    name={"dailyNotifications"}
                    type="checkbox"
                    component={Checkbox}
                    label="Enable Daily Notifications?"
                    helpText="Do you want to receive daily notification and reminder emails?"
                  />
                )}

                {!viewer.usingSso && (
                  <Field
                    withFieldset
                    name={"currentPassword"}
                    autoComplete={"currentPassword"}
                    type="password"
                    component={TextField}
                    label="Current password"
                    helpText="Enter your current password to update your profile."
                  />
                )}
              </Fieldset>

              {!viewer.usingSso && (
                <Fieldset>
                  <legend>Optional</legend>
                  <Field
                    withFieldset
                    name={"newPassword"}
                    autoComplete={"newPassword"}
                    type="password"
                    component={TextField}
                    label="New password"
                    helpText="Your password should be at least eight (8) characters. You may like to simply combine a few words to make it easy to remember!"
                  />

                  <Field
                    data-qa="passwordConfirmation"
                    name={"newPasswordConfirmation"}
                    withFieldset
                    fullWidth
                    type="password"
                    autoComplete="new-password"
                    component={TextField}
                    label="Confirm password"
                    helpText="Confirm the password you wish to set."
                  />
                  {status && <PasswordErrorWell />}
                </Fieldset>
              )}

              <div className="center mt3">
                <Button
                  fullWidth
                  id="update-profile"
                  disabled={!dirty || hasErrors || isSubmitting}
                  data-qa="update-profile"
                  type="submit"
                  label="Update profile"
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}
export default UpdateProfile
